export default {
  "apmMaersk": [
    {
      origin: "SD",
      destination: "SD",
      name: "SDSD",
      nameText: "Origin: SD | Destination: SD",
    },
    {
      origin: "SD",
      destination: "CY",
      name: "SDCY",
      nameText: "Origin: SD | Destination: CY",
    },
    {
      origin: "CY",
      destination: "SD",
      name: "CYSD",
      nameText: "Origin: CY | Destination: SD",
    },
    {
      origin: "CY",
      destination: "CY",
      name: "CYCY",
      nameText: "Origin: CY | Destination: CY",
    },
  ],
  "evergreen": [
    {
      origin: "Y",
      destination: "Y",
      name: "Reefer-Cargo",
      nameText: "Reefer Cargo",
    },
    {
      origin: "N",
      destination: "N",
      name: "Non-Reefer-Cargo",
      nameText: "Non Reefer Cargo",
    },
  ],
  "hmm": [
    {
      origin: "DR",
      destination: "DR",
      name: "DRDR",
      nameText: "Origin: DR | Destination: DR",
    },
    {
      origin: "DR",
      destination: "CY",
      name: "DRCY",
      nameText: "Origin: DR | Destination: CY",
    },
    {
      origin: "CY",
      destination: "DR",
      name: "CYDR",
      nameText: "Origin: CY | Destination: DR",
    },
    {
      origin: "CY",
      destination: "CY",
      name: "CYCY",
      nameText: "Origin: CY | Destination: CY",
    },
  ],
  "msc": [
    {
      origin: "ALL",
      destination: "ALL",
      name: "ALL",
      nameText: "ALL",
    },
  ],
  "one": [
    {
      origin: "ALL",
      destination: "ALL",
      name: "ALL",
      nameText: "ALL",
    },
  ]

}
